<template>
	<div>appeal</div>
</template>

<script>
//import AdminPendingCard from '@/views/admin/components/AdminPendingCard'

export default {
	name: 'AdminReportPage',
	//components: { AdminPendingCard },
	data: () => ({}),
	methods: {},
	mounted() {},
};
</script>

<style scoped></style>
